<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】离职申请' : (todo == 'audit' ? '【审核】离职申请' : '【查看】离职申请')"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="申请人" prop="driverName">
              <el-input v-model="dataForm.driverName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="driverPhone">
              <el-input v-model="dataForm.driverPhone" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="入职日期" prop="entryDate">
              <el-input v-model="dataForm.entryDate" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="期望离职日期" prop="expectQuitDate">
              <el-input v-model="dataForm.expectQuitDate" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="离职原因" prop="quitType">
              <el-select class="item-choose" v-model="dataForm.quitType" :disabled="true" style="width: 100%;">
                <el-option
                  v-for="item in dictTypeMap.quit_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间" prop="requestTime">
              <el-input v-model="dataForm.requestTime" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="离职描述" prop="quitDesc">
              <el-input :rows="4" :disabled="true" type="textarea" v-model="dataForm.quitDesc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="附件">
              <al-upload :fileData="fileList" :fileUrl="uploadUrl" :isView="true" :format="'file'" :type="'quit'"
                         :limit="2" :businessType="5006" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <h3 style="margin-left: 20px">审核信息</h3>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="审核意见" prop="auditStatus">
              <el-radio-group v-model="dataForm.auditStatus" :disabled="todo === 'view'">
                <el-radio :label="1">审核通过</el-radio>
                <el-radio :label="2">审批不通过</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="审核意见" prop="auditDesc">
              <el-input :rows="4"
                        :disabled="todo === 'view'"
                        type="textarea"
                        v-model="dataForm.auditDesc"
                        placeholder="审核意见"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="审核人" prop="auditName">
              <el-input v-model="dataForm.auditName" :disabled="todo === 'view'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核时间" prop="auditTime">
              <el-date-picker v-model="dataForm.auditTime"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择时间"
                              :disabled="todo === 'view'"
                              style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        driverName: null,
        driverPhone: null,
        entryDate: null,
        expectQuitDate: null,
        quitType: null,
        requestTime: null,
        quitDesc: null,
        auditDesc: null,
        auditStatus: null,
        auditName: null,
        auditTime: null,
        status: 0
      },
      uploadUrl: '',
      fileList: [],
      todo: '',
      dataRule: {
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isView () {
      return this.todo === 'view' || this.todo === 'audit'
    }
  },
  methods: {
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
      this.fileList = []
    },
    getFileData (data) {
      this.fileList = data.fileList
      this.dataForm.enclosureInfoList = []
      for (let i = 0; i < this.fileList.length; i++) {
        this.dataForm.enclosureInfoList.push(this.fileList[i])
      }
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.fileList.push({
            id: infoList[i].id,
            name: infoList[i].name,
            url: infoList[i].url,
            businessType: infoList[i].businessType,
            status: infoList[i].status,
            uid: infoList[i].uid
          })
        }
      }
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/driverQuit/findById/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          if (data) {
            this.dataForm.id = data.id
            this.dataForm.driverName = data.driverName
            this.dataForm.driverPhone = data.driverPhone
            this.dataForm.entryDate = data.entryDate
            this.dataForm.expectQuitDate = data.expectQuitDate
            this.dataForm.quitType = data.quitType
            this.dataForm.requestTime = data.requestTime
            this.dataForm.quitDesc = data.quitDesc
            this.dataForm.auditStatus = data.auditStatus
            this.dataForm.auditDesc = data.auditDesc
            this.dataForm.auditName = data.auditName
            this.dataForm.auditTime = data.auditTime
            this.initFileList(data)
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/driverQuit/audit`),
            method: 'POST',
            data: {
              id: this.dataForm.id,
              auditStatus: this.dataForm.auditStatus,
              auditDesc: this.dataForm.auditDesc,
              auditName: this.dataForm.auditName,
              auditTime: this.dataForm.auditTime
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
